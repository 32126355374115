.search {
  max-width: 640px;
  margin: 0 auto;
  padding: 20px 0;
}

img {
  width: 101px;
  height: 91px;
  object-fit: cover;
}

.row {
  display: flex;
  text-align: center;
  margin-bottom: 16px;
}

.row > p {
  width: 100%;
}

.form {
  width: 100%;
  max-width: 450px;
  margin-left: auto;
  margin-right: auto;
}

.spaceBottom {
  padding-bottom: 24px;
}

.responsive {
  flex-direction: column;
}

.searchHere {
  margin-top: 16px;
}

.center {
  justify-content: center;
}

.between {
  align-items: center;
}

.margintop {
  margin-top: 20px;
}



@media (min-width: 720px) {
  .search {
    padding-top: 100px;
  }

  .row {
    text-align: left;
  }

  .form {
    margin-left: 0;
    margin-right: 0;
  }

  .between {
    align-items: center;
    justify-content: space-between;
  }

  .responsive {
    flex-direction: row;
  }

  .margintop {
    margin-top: 40px;
  }

  .searchHere {
    width: 140px;
    margin: 0;
  }

  .logHere {
    width: 390px;
  }
}
