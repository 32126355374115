.spinner {
  position: absolute;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: RGBA(0,0,0,0.7);
  color: white;
}

.innerSpinner {
  padding-top: 40vh;
}
