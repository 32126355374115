.row {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
  min-height: 60px;
}

.col p {
  margin: 0;
}

.col.left > p {
  margin-top: 16px;
}

.firstCol.left {
  padding-top: 50px;
}

.inputContainer {
  width: 100%;
}

.buttonsRow {
  padding: 50px 20px 0;
  justify-content: right
}

.buttonsRow {
  flex-wrap: wrap;
  padding: 0px 20px 0;
  justify-content: center;
}

.buttonContainer {
  padding: 8px;
  width: 270px;
}

@media (min-width: 1200px) {
  .row {
    flex-direction: row;
  }

  .col.left > p {
    margin-top: 0;
  }

  .col {
    align-items: center;
    padding-bottom: 16px;
  }
  
  .firstCol.right {
    padding-top: 50px;
  }

  .left {
    width: 20%;
    justify-content: right;
    padding-right: 16px;
    background-color: #221130;
  }

  .right {
    width: 80%;
    padding-left: 16px;
  }

  .buttonsRow {
    justify-content: right;
    justify-items: flex-end;
    padding: 16px 0 16px 16px;
    margin-right: -8px;
  }
}