.row {
  display: flex;
  flex-direction: column;
}

.col {
  display: flex;
}

.calendar {
  width: 100%;
  padding-bottom: 0;
}

.slotsTop {
  display: none;
}

.slots {
  width: 100%;
  padding-top: 20px;
}

.slotsCol {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
  
.slotButtons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 16px;
}

.calendarButtons {
  display: flex;
  overflow: visible;
  flex-direction: row;
  justify-content: space-between;
}

.formButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.formButtons > div {
  width: 180px;
  padding: 0 8px 8px 8px;
}

.ms-calendarDay-hoverStyle {
  background-color: red;
}

.hasOpenSlots:after {
  content: " ";
  display: block;
  background-color: #FFF200;
  border-radius: 50%;
  height: 0;
  width: 0;
  position: absolute;
  bottom: 13px;
  margin-left: -4px;
  padding: 6px;
  left: 50%;
}

.zone {
  font-size: 14px;
}



@media (min-width: 1200px) {
  .row {
    flex-direction: row;
  }

  .slotsTop {
    display: block;
  }

  .calendar {
    width: 60%;
    padding-bottom: 100px;
  }

  .slots {
    width: 40%;
    background-color: #221130;
    padding-bottom: 100px;
  }

  .slotButtons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}