@font-face {
  font-family: LuloCleanOneBold;
  src: url("fonts/LuloCleanOneBold.otf") format("opentype");
}

@font-face {
  font-family: ProximaNovaFont;
  src: url("fonts/ProximaNovaFont.otf") format("opentype");
}

html {
  
  font-size: 24px;
}

body, body.ms-Fabric--isFocusHidden {
  color: white;
  font-family: ProximaNovaFont;
}

h2, h3 {
  margin: 0;
  font-weight: normal;
}

h2 {
  font-family: LuloCleanOneBold;
  font-size: 2.1rem;
  line-height: 2.4rem;
  letter-spacing: -0.5rem;
}

p {
  margin: 20px 0;
}

.app {
  min-height: 100vh;
  background-color: #351D4A;
}

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 10px;
}

@media (min-width: 720px) {
  h2 {
    font-size: 2.4rem;
  }

  .container {
    padding: 0 30px;
  }
}